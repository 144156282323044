import React from 'react';
import Layout from '../layouts/Layout';
import MetaData from '../components/MetaData';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

export default function ForgotPassword() {
	return (
		<Layout>
			<MetaData title="Forgot password" />
			<ForgotPasswordForm />
		</Layout>
	);
}
