import React from 'react';
import { string } from 'prop-types';
import useStyles from '../hooks/useStyles';
import { PATH } from '../consts';
import Button from './button/Button';
import { Link } from 'gatsby';

InviteInvalid.propTypes = {
	email: string
};

const MESSAGE = {
	header: 'Check your email',
	subHeading: `We've sent your password reset instructions to`,
	signInLink: 'Back to sign in'
};

export default function InviteInvalid({ email }) {
	const classes = useStyles(styles);
	return (
		<div css={classes.content}>
			<h1 css={classes.heading} className="h2">
				{MESSAGE.header}
			</h1>
			<p css={classes.subHeading}>{MESSAGE.subHeading}</p>
			<p css={classes.email}>{email}</p>
			<Link to={PATH.signIn}>
				<Button type="link" css={classes.signInLink} className="full-width" tabDisabled>
					{MESSAGE.signInLink}
				</Button>
			</Link>
		</div>
	);
}

function styles({ css, theme: { font } }) {
	return {
		content: css`
			text-align: center;
		`,
		heading: css`
			font-size: 2.4rem;
			margin-bottom: 16px;
			font-weight: ${font.weight.normal};
		`,
		subHeading: css`
			margin-bottom: 0px;
		`,
		email: css`
			margin-bottom: 16px;
			font-weight: ${font.weight.bold};
		`
	};
}
