import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import FormHeading from './FormHeading';
import Button from './button/Button';
import TextField from './form/TextField';
import { getQaKey as qa } from '../util/qa';
import { useInitiatePasswordReset } from '../util/api';
import useStyles from '../hooks/useStyles';
import useGlobal from '../hooks/useGlobal';
import { PATH } from '../consts';
import ResetPasswordLinkSent from './ResetPasswordLinkSent';

const MESSAGE = {
	pageHeading: 'Forgot your password?',
	pageSubHeading:
		'Enter the email you signed up with below. We’ll send you instructions on how to reset your password.',
	emailLabel: 'Your email',
	submit: 'Send me instructions',
	signInLink: 'Back to sign in'
};

export default function ForgotPasswordForm() {
	const classes = useStyles(styles);
	const { email, setGlobal } = useGlobal();
	const [emailSent, setEmailSent] = useState(false);
	const [{ data, loading }, resetPassword] = useInitiatePasswordReset();

	const onChange = ({ target: { value: email } }) => {
		setGlobal({ email });
	};

	const onNavigate = () => {
		setGlobal({ email });
	};

	const onSubmit = e => {
		e.preventDefault();
		resetPassword({ json: email });
	};

	useEffect(() => {
		if (data === true) setEmailSent(true);
	}, [data]);

	if (emailSent) return <ResetPasswordLinkSent email={email} />;

	return (
		<>
			<FormHeading
				heading={MESSAGE.pageHeading}
				subHeading={MESSAGE.pageSubHeading}
				css={[classes.container, classes.formHeading]}
			/>
			<section css={classes.container}>
				<form onSubmit={onSubmit} css={classes.form}>
					<TextField
						innerCss={{ root: classes.inputRoot }}
						autoFocus
						autoComplete="email"
						name="email"
						label={MESSAGE.emailLabel}
						onChange={onChange}
						placeholder="email@yourcompany.com"
						required
						type="email"
						value={email}
						{...qa('input:email')}
					/>
					<Button className={'full-width'} type="submit" loading={loading} disabled={loading} qaKey="btn:instructions">
						{MESSAGE.submit}
					</Button>
				</form>
				<div css={classes.otherActions}>
					<Link to={PATH.signIn} {...qa('link:sign-in')} onClick={onNavigate}>
						{MESSAGE.signInLink}
					</Link>
				</div>
			</section>
		</>
	);
}

const styles = ({ css }) => ({
	container: css`
		min-width: 320px;
		max-width: 360px;
	`,
	formHeading: css`
		margin-bottom: 24px;
	`,
	form: css`
		width: 100%;
		position: relative;
		margin-bottom: 24px;
	`,
	inputRoot: css`
		height: 96px;
	`,
	otherActions: css`
		text-align: center;
	`
});
